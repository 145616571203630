<template>
  <router-view />
</template>
<script>
/* eslint-disable */
export default {
  name: "App",
  data() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
