import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
/* eslint-disable */
export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    mcolor: "warning",
    isNavFixed: true,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    token: null,
    user: {}
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_SHOW_NAVBAR: (state, value) => {
      state.showNavbar = value
    },
    SET_SHOW_SIDEBAR: (state, value) => {
      state.showSidenav = value
    },
    SET_SHOW_FOOTER: (state, value) => {
      state.showFooter = value
    }
  },
  getters: {},
  actions: {
    setToken( {commit}, token ) {
      commit('SET_TOKEN', token);
    },
    setUser( {commit}, user ) {
      commit('SET_USER', user);
    }
  },
  // eslint-disable-next-line
  plugins: [createPersistedState()],
});