<!--prettier-ignore-->
<template>
  <div class="py-4 container-fluid" style="min-height: 450px">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="card">
          <div class="card-body">
            <a href="#" class="btn btn-primary" v-on:click="print"
              ><i class="fa fa-download"></i> Unduh Kartu Alumni</a
            >
            <pdf v-if="pdfFile" :src="pdfFile" :page="1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import pdf from "pdfvuer";
import axios from "@/libs/axios";
export default {
  name: "print-kartu-alumni",
  components: {
    pdf,
  },
  data() {
    return {
      pdfFile: "",
    };
  },
  mounted() {
    this.checkProfile()
  },
  methods: {
    getPdf() {
      const loader = this.$loading.show()
      axios
        .get("/card/" + this.$route.params.id, {
          responseType: "blob",
        })
        .then((res) => {
          loader.hide()
          var file = new File([res.data], "Kartu Alumni");
          this.pdfFile = window.URL.createObjectURL(file);
        })
        .catch((err) => {
          loader.hide()
          this.$swal({
            title: "Oops Maaf",
            text: "Terdapat Masalah Pada Jaringan!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    },
    async showPdf(pdfFile) {
      let container = document.getElementById("pageContainer");
      let pdfViewer = new PDFViewer({
        container: container,
      });
      let pdf = await pdfjsLib.getDocument(pdfFile);
      pdfViewer.setDocument(pdf);
    },
    print() {
      const link = document.createElement("a");
      link.href = this.pdfFile;
      link.download = "Kartu Alumni.pdf";
      link.click();
    },
    checkProfile() {
      axios
        .get(
          "dashboard/" +
            this.$route.params.id
        )
        .then((res) => {
          if (res.data.profile_completed == 0) {
            this.$swal({
              title: "Oops Maaf",
              text: "Mohon Lengkapi Data Profil!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push("/form_profile/" + this.$route.params.id);
              }
            });
          }else if (res.data.tracer_completed == 0) {
            this.$swal({
              title: "Oops Maaf",
              text: "Mohon Lengkapi Data Tracer Study!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push("/tracer_study/" + this.$route.params.id);
              }
            });
          }else{
            this.getPdf();
          }
        })
        .catch((err) => {
          this.$swal({
            title: "Oops Maaf",
            text: "Terdapat Masalah Pada Jaringan!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    },
  },
};
</script>

<style>
#pageContainer {
  margin: auto;
  width: 80%;
}

div.page {
  display: inline-block;
}
</style>

