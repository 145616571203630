<template>
  <div class="py-4 container-fluid" style="min-height: 450px">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <mini-cards
              v-if="profil_lengkap === 'ya'"
              value="Profil Alumni"
              iconName="person"
              textCap="text-success text-sm font-weight-bolder"
              percentage="Profil Sudah Lengkap"
              iconClass="text-white"
              iconBackground="bg-gradient-success"
            />
            <mini-cards
              v-else-if="profil_lengkap === 'tidak'"
              value="Profil Alumni"
              iconName="person"
              textCap="text-danger text-sm font-weight-bolder"
              percentage="Profil Belum Lengkap"
              iconClass="text-white"
              iconBackground="bg-gradient-primary"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <mini-cards
              v-if="survey_lengkap === 'ya'"
              value="Tracer Study"
              iconName="location_searching"
              textCap="text-success text-sm font-weight-bolder"
              percentage="Tracer Study Sudah Terisi"
              iconClass="text-white"
              iconBackground="bg-gradient-success"
            />
            <mini-cards
              v-else-if="survey_lengkap === 'tidak'"
              value="Tracer Study"
              iconName="location_searching"
              textCap="text-danger text-sm font-weight-bolder"
              percentage="Tracer Study Belum Terisi"
              iconClass="text-white"
              iconBackground="bg-gradient-primary"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <mini-cards
              value="Update Tracer Study"
              iconName="info"
              :percentage="date(exp_date)"
              iconClass="text-white"
              iconBackground="bg-gradient-warning"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import MiniCards from "./components/MiniCards.vue";
import axios from "@/libs/axios";

export default {
  name: "dashboard",
  components: {
    MiniCards,
  },
  data() {
    return {
      profil_lengkap: "",
      survey_lengkap: "",
      exp_date: "",
    };
  },
  methods: {
    date: function (date) {
      return this.$moment(date).format('DD MMMM YYYY');
    },
    checkData() {
          axios.get('dashboard/'+ this.$route.params.id).then(res => {
          if(res.data.profile_completed == 1){
            this.profil_lengkap = 'ya'
          }
          else{
            this.profil_lengkap = 'tidak'
          }
          if(res.data.tracer_completed == 1){
            this.survey_lengkap = 'ya'
          }
          else{
            this.survey_lengkap = 'tidak'
          }
          if(res.data.expired_date != null){
            this.exp_date = res.data.expired_date
          }
          else{
            this.exp_date = "Belum Ada Tanggal Kadaluarsa"
          }
        }).catch ((err) => {
          console.log(err);
        })
      },
  },
  mounted() {
    // this.load();
    this.checkData();
    // window.location.href = "/form_profile";
    // $("#modalDokumen").addClass("show");
    // $(".modal").css("display", "block");
    // $(".closeModal").click(function () {
    //   $("#modalDokumen").removeClass("show");
    //   $(".modal").css("display", "none");
    // });
    // $(".saveModal").click(function () {
    // });
  },
};
</script>
