/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from "./material-dashboard";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import 'vue-loading-overlay/dist/vue-loading.css';
import VueLoading from "vue-loading-overlay";
import moment from 'moment';

moment.locale('id');
const appInstance = createApp(App);
appInstance.config.globalProperties.$moment=moment;
appInstance.use(store);
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.use(VueSweetalert2);
appInstance.use(
  VueLoading,
  {
    container: null,
    canCancel: false,
    loader: "bars",
    backgroundColor: "#fff",
    color: "#006CEE",
    opacity: 0.9,
    isFullPage: false
  },
  {
    // slots
  }
);

appInstance.mount("#app");