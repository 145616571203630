<!-- prettier-ignore -->
<template>
  <div class="card">
    <div class="card-body px-0 pb-2">
      <form v-on:submit.prevent="submit">
        <div class="row p-4">
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="col-12">Nama Lengkap <span class="text-danger">*</span></label><br/>
              <input type="text" placeholder="Masukkan nama lengkap" class="form-control" v-model="profil.name" name="name" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">No Ijazah (opsional)</label><br/>
              <input type="text" placeholder="Masukkan nomor ijazah" class="form-control" v-model="profil.diploma_number" name="diploma_number" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">IPK <span class="text-danger">*</span></label><br/>
              <input type="number" step="0.01" placeholder="Masukkan IPK, contoh: 3.5, 3.9, 4.0" class="form-control" v-model="profil.gpa" name="gpa" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">NIM <span class="text-danger">*</span></label><br/>
              <input type="text" placeholder="Masukkan NIM" class="form-control" v-model="profil.nim" name="nim" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">NIK <span class="text-danger">*</span></label><br/>
              <input type="number" placeholder="Masukkan NIK" class="form-control" v-model="profil.nik" name="nik" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Tempat Lahir (opsional)</label><br/>
              <input type="text" placeholder="Masukkan tempat lahir" class="form-control" v-model="profil.birth_place" name="birth_place" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Tanggal Lahir <span class="text-danger">*</span></label><br/>
              <input type="date" placeholder="Masukkan tanggal lahir" class="form-control" v-model="profil.birth_date" name="birth_date" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Tahun Masuk (opsional)</label><br/>
              <input type="number" placeholder="Masukkan tahun masuk" min="1900" max="2099" step="1" class="form-control" v-model="profil.entry_year" name="entry_year" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Tahun Lulus (opsional)</label><br/>
              <input type="number" placeholder="Masukkan tahun lulus" min="1900" max="2099" step="1" class="form-control" v-model="profil.graduate_year" name="graduate_year" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Fakultas (opsional)</label><br/>
              <div class="col-12">
                  <Select2 v-model="profil.faculty_id" :options="myOptions" :settings="{ placeholder: 'Pilih Fakultas' }" />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Jurusan (opsional)</label><br/>
              <div class="col-12">
                  <Select2 v-model="profil.departement_id" :options="myOptions2" :settings="{ placeholder: 'Pilih Jurusan' }" />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">No.Hp (opsional)</label><br/>
              <input type="text" placeholder="Masukkan nomor HP" class="form-control" v-model="profil.phone_number" name="phone_number" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Email <span class="text-danger">*</span></label><br/>
              <input type="text" placeholder="Masukkan alamat email" class="form-control" v-model="profil.email" name="email" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Jenis Kelamin (opsional)</label><br/>
              <div class="col-12">
                <select name="gender" id="gender" class="form-control" v-model="profil.gender">
                  <option disabled selected value="">Pilih Jenis Kelamin</option>
                  <option value="Laki-laki">Laki-laki</option>
                  <option value="Perempuan">Perempuan</option>
                </select> 
                  <!-- <Select2 v-model="profil.Provinsi" :options="myOptions3" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Negara (opsional)</label
              ><br />
              <div class="col-12">
                <Select2
                  ref="country"
                  v-model="profil.country"
                  :options="countries"
                  :settings="{ placeholder: 'Pilih Negara Domisili' }"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Provinsi (opsional)</label
              ><br />
              <div class="col-12" v-if="profil.country == 'Indonesia'">
                <Select2
                  id="province"
                  ref="province"
                  v-model="profil.province"
                  :options="provinces"
                  :settings="{ placeholder: 'Pilih Provinsi Domisili' }"
                />
              </div>
              <input
                v-else
                placeholder="Masukkan Provinsi domisili"
                type="text"
                class="form-control"
                v-model="profil.province"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Kabupaten (opsional)</label
              ><br />
              <div class="col-12" v-if="profil.country == 'Indonesia'">
                <Select2
                  v-model="profil.regency"
                  :options="regencies"
                  :settings="{ placeholder: 'Pilih Kota/Kabupaten Domisili' }"
                />
              </div>
              <input
                v-else
                placeholder="Masukkan Kabupaten domisili"
                type="text"
                class="form-control"
                v-model="profil.regency"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Kecamatan (opsional)</label
              ><br />
              <div class="col-12" v-if="profil.country == 'Indonesia'">
                <Select2
                  v-model="profil.district"
                  :options="districts"
                  :settings="{ placeholder: 'Pilih Kecamatan Domisili' }"
                />
              </div>
              <input
                v-else
                placeholder="Masukkan Kecamatan domisili"
                type="text"
                class="form-control"
                v-model="profil.district"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Alamat (opsional)</label
              ><br />
              <input
                placeholder="Masukkan Alamat domisili (desa/jalan)"
                type="text"
                class="form-control"
                v-model="profil.address"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Sosial Media (opsional)</label><br/>
              <input type="text" placeholder="Masukkan sosial media" class="form-control" v-model="profil.social_media" name="social_media" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Organisasi (opsional)</label><br/>
              <input type="text" placeholder="Masukkan organisasi" class="form-control" v-model="profil.organization" name="organization" isrequired="true">
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Pencapaian (opsional)</label><br/>
              <input type="text" placeholder="Masukkan pencapaian" class="form-control" v-model="profil.achievement" name="achievement" isrequired="true">
            </div>
          </div>
          <div class="col-lg-8"></div>
          <div class="col-lg-2 col-sm-6">
            <vmd-button
                class="my-4 mb-2"
                variant="gradient"
                color="info"
                fullWidth
                >Simpan
              </vmd-button>
          </div>
          <div class="col-lg-2 col-sm-6">
            <vmd-button
                class="my-4 mb-2"
                variant="gradient"
                color="light"
                fullWidth
                >Batal
              </vmd-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Select2 from 'vue3-select2-component';
import VmdButton from "@/components/VmdButton.vue";
import axios from "@/libs/axios";
let self;
export default {
  name: "form-card",
  components: {
    Select2,
    VmdButton
  },
  created: function() {
    self = this;
    this.load_faculty();
    this.load_countries();
  },
  data() {
      return {
          src: '',
          photo_url: '',
          ktp_url: '',
          ijazah_url: '',
          preview: null,
          preview_ktp: null,
          preview_ijazah: null,
          myOptions: [],
          myOptions2: [],
          myOptions: [],
          myOptions2: [],
          countries: [],
          provinces: [],
          regencies: [],
          districts: [],
          villages: [],
          profil:{
              name:'',
              diploma_number:'',
              gpa:'',
              nim:'',
              nik:'',
              birth_place:'',
              birth_date:'',
              entry_year:'',
              graduate_year:'',
              phone_number:'',
              country: '',
              province: '',
              regency: '',
              district: '',
              address:'',
              email:'',
              faculty_id:'',
              departement_id:'',
              gender:'',
              social_media:'',
              organization:'',
              achievement:'',
              photo:'',
              identity_card:'',
              bachelor_certificate:'',
          }
      }
  },
  watch: {
    "profil.faculty_id": {
      handler(id) {
        this.load_departement(id);
      },
      // force eager callback execution
      immediate: true,
    },
    "profil.country": {
      handler(id) {
        this.load_provinces(id);
      },
      // force eager callback execution
      immediate: true,
    },
    "profil.province": {
      handler(id) {
        let data = this.provinces.find(element => element.text === id);
        if(data && data.province_id) {
          this.load_regencies(data.province_id);
        }
      },
      // force eager callback execution
      immediate: true,
    },
    "profil.regency": {
      handler(id) {
        let data = this.regencies.find(element => element.text === id);
        if(data && data.regency_id) {
          this.load_districts(data.regency_id);
        }
      },
      // force eager callback execution
      immediate: true,
    }
  },
  methods: {
    
    load_faculty() {
      axios
        .get("list_faculty")
        .then((res) => {
          for (var i = 0; i < res.data.faculty.length; i++) {
            this.myOptions.push({
              id: res.data.faculty[i].id,
              text: res.data.faculty[i].faculty_name,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load_departement(id) {
      if (id) {
        axios
          .get("list_departement/" + id)
          .then((res) => {
            this.myOptions2 = [];
            for (var i = 0; i < res.data.Departement.length; i++) {
              this.myOptions2.push({
                id: res.data.Departement[i].id,
                text: res.data.Departement[i].departement_name,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    load_countries() {
        axios
          .get("country")
          .then((res) => {
            this.countries = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.countries.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    load_provinces(data) {
      if (data == 'Indonesia') {
        axios
          .get("province")
          .then((res) => {
            this.provinces = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.provinces.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name,
                province_id: res.data.data[i].id,
              });
            }
            let data = this.provinces.find(element => element.text === this.profil.province);
            if(data && data.province_id) {
              this.load_regencies(data.province_id);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    load_regencies: function(province_id) {
        axios
          .get("regency/"+province_id)
          .then((res) => {
            this.regencies = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.regencies.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name,
                regency_id: res.data.data[i].id,
              });
            }
            let data = this.regencies.find(element => element.text === this.profil.regency);
            if(data && data.regency_id) {
              this.load_districts(data.regency_id);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    load_districts: function(regency_id) {
        axios
          .get("district/"+regency_id)
          .then((res) => {
            this.districts = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.districts.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    uploadPhoto() {
      self.profil.photo = self.$refs.file.files[0];
      console.log(self.profil.photo);
      if (self.$refs.file.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        reader.readAsDataURL(self.$refs.file.files[0]);
      }
    },
    uploadKTP() {
      self.profil.identity_card = self.$refs.ktp.files[0];
      console.log(self.profil.identity_card);
      if (self.$refs.ktp.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview_ktp = e.target.result;
        }
        reader.readAsDataURL(self.$refs.ktp.files[0]);
      }
    },
    uploadIjazah() {
      self.profil.bachelor_certificate = self.$refs.ijazah.files[0];
      console.log(self.profil.bachelor_certificate)
      if (self.$refs.ijazah.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview_ijazah = e.target.result;
        }
        reader.readAsDataURL(self.$refs.ijazah.files[0]);
      }
    },
    submit(){
      let formData = new FormData();
      for (const key in self.profil) {
        if(self.profil[key] && self.profil[key] != null && self.profil[key] && '' || self.profil[key] && 'null'){
          formData.append(key, self.profil[key]);
        }
      }
      formData.append('_method', 'POST')
      const url = "alumni";
      axios
        .post(url, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          })
        .then(function (response) {
          self.$swal({
            title: 'Sukses',
            text: response.data.messege,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then((result) => {
            self.$router.push('/admin/data_alumni')
          })
        })
        .catch(error => {
          var obj = JSON.stringify(error.response.data)
          var dt = JSON.parse(obj);
          this.swalAlert(dt.message, 'Gagal', 'error')
        });
    },
    swalAlert(text,title,icon){
      this.$swal({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }     
  }
};
</script>
<style>
.select2 {
  width:100%!important;
}
</style>