import axiosLib from "axios";
import store from "@/store";

const axios = axiosLib.create({
  baseURL: process.env.VUE_APP_BASE_API + process.env.VUE_APP_VERSION,
  timeout: 1000*60,
});
axios.interceptors.request.use(
  (config) => {
    if (store.state.token) {
      config.headers["Authorization"] = "Bearer " + store.state.token;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    if (response.status < 200 && response.status >= 300) {
      return Promise.reject(response);
    } else {
      return response;
    }
  },
  (error) => {
    console.log("err " + error.response.data);
    if (error.response.status == "500") {
      alert("Terjadi masalah pada server")
    }else if (error.response.status == "403" && window.location.pathname != '/sign-in' && window.location.pathname != '/admin/sign-in') {
      window.location.href = "/sign-in"
    }else if (error.response.status == "401") {
      localStorage.removeItem("loggedIn")
      localStorage.removeItem("vuex")
      window.location.href = "/sign-in"
    }
    return Promise.reject(error);
  }
);

export default axios;
