<!-- prettier-ignore -->
<template>
  <!-- <navbar btnBackground="bg-gradient-warning" /> -->
  <div
    class="page-header align-items-start min-vh-100"
    :style="{ backgroundImage: 'url(' + bg + ')' }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto mx-auto">
      <div class="row h-100">
         <div class="col-lg-5 col-md-8 col-12 mx-auto my-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
                <div class="alert mb-0" style="background-color: #ccdbfd;" role="alert">
                  <center><strong>DIGITALISASI KARTU ALUMI</strong></center>
                  <ol class="mt-2">
                    <li>
                      Sign In default ke dka.uns.ac.id
                    </li>
                    <li>
                      Isikan NIK/ email aktif
                    </li>
                    <li>
                      Masukkan password tanggalbulantahun (ddmmyyyy) tanpa spasi
                    </li>
                    <li>
                      Isi lengkap form dan lampiran yang dipersyaratkan
                    </li>
                    <li>
                      Simpan kartu
                    </li>
                    <li>
                      Bagi yang belum berhasil Sign In (belum memiliki akun), register dengan klik Sign Up (syarat email aktif), selanjutnya menunggu proses validasi maka Sign In mandiri akan aktif (akun sesuai pada saat mengisi register Sign Up)
                    </li>
                    <li>
                      Setelah berhasil login dengan akun default, demi keamanan silakan melakukan update password masing-masing melalui menu pengaturan akun
                    </li>
                    <li>
                      UPDATE BIODATA rutin secara online melalui akun digital masing-masing.
                    </li>
                    <li>
                      Bantuan lebih lanjut kirim ke ikauns@mail.uns.ac.id
                    </li>
                  </ol>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-8 col-12 mx-auto my-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="
                  bg-gradient-info
                  shadow-success
                  border-radius-lg
                  py-3
                  pe-1
                "
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Sistem Kartu Alumni UNS
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start mt-3" @submit.prevent="login">
                <div class="mb-3">
                  <div class="input-group input-group-outline my-3">
                    <label class="form-label">Email/NIK</label>
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      v-model="form.username"
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <div class="input-group input-group-outline my-3">
                    <label class="form-label">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="password"
                      v-model="form.password"
                    />
                  </div>
                </div>
                <!-- <vmd-switch id="rememberMe">Remember me</vmd-switch> -->
                <div class="text-center">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    fullWidth
                    >Sign In</vmd-button
                  >
                </div>
                <p class="mt-4 text-sm text-center">
                  Belum memiliki akun?
                  <router-link
                    :to="{ name: 'SignUp' }"
                    class="text-info text-gradient font-weight-bold"
                    >Sign Up</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }}
              <i class="fa fa-heart" aria-hidden="true"></i> <span></span>
              <a
                href="https://uns.ac.id"
                class="font-weight-bold text-white"
                target="_blank"
                >IKA UNS</a
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <!-- <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://www.creative-tim.com/presentation"
                  class="nav-link text-white"
                  target="_blank"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.creative-tim.com/blog"
                  class="nav-link text-white"
                  target="_blank"
                  >Blog</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.creative-tim.com/license"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >License</a
                >
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
/* eslint-disable */
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import VmdInput from "@/components/VmdInput.vue";
// import VmdSwitch from "@/components/VmdSwitch.vue";
import setMaterialInput from "@/assets/js/material-input.js";
import VmdButton from "@/components/VmdButton.vue";
import axios from "@/libs/axios";
import bg from "@/assets/img/foto_gedung_rektorat.png";

export default {
  name: "sign-in",
  components: {
    // Navbar,
    // VmdInput,
    // VmdSwitch,
    VmdButton,
  },
  data() {
    return {
      loggedIn: localStorage.getItem("loggedIn"),
      token: localStorage.getItem("token"),
      userid: localStorage.getItem("userid"),
      dataKosong: null,
      loginFailed: null,
      bg,
      form: {
        username: "",
        password: "",
      },
      error: undefined,
    };
  },
  methods: {
    login() {
      axios
        .post("login", {
          username: this.form.username,
          password: this.form.password,
        })
        .then((res) => {
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("token", res.data.token);
          this.$store.dispatch("setToken", res.data.token);
          this.$store.dispatch("setUser", res.data.user);
          localStorage.setItem("userid", res.data.user.id);
          this.loggedIn = true;
          this.token = res.data.token;
          this.userid = res.data.user.id;
          this.$store.state.hideConfigButton = false;
          this.$store.state.showNavbar = true;
          this.$store.state.showSidenav = true;
          this.$store.state.showFooter = true;
          this.$swal({
            title: "Sukses",
            text: "Berhasil Sign In",
            icon: "success",
            showConfirmButton: false,
            showCancelButton: false,
            toast: true,
            timer: 3000,
            position: "top-end",
          });
          return this.$router.push("/form_profile/" + res.data.user.id);
        })
        .catch((error) => {
          this.error = error.response.data.message;
          if (this.error != undefined) {
            this.$swal({
              title: "Sign In Gagal !",
              text: this.error,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            });
          }
        });
    },
  },
  mounted() {
    setMaterialInput();
    if (this.loggedIn) {
      return this.$router.push("/form_profile/" + this.userid);
      // this.$router.go(-1);
    }
  },
};
</script>
